.backgrnd {
  background: linear-gradient(266.1deg, #edfcff 43.75%, #ebfcff 85.16%);
  position: relative;
  /* padding: 0px; */
}

.headline {
  font-family: "Century Gothic Paneuropean", sans-serif;
  position: relative;
  font-size: 45px;
  font-weight: 400;
  line-height: 1.2;
}

.headlinetext {
  font-family: "Poppins";
  position: relative;
  font-size: 20px !important;
  font-weight: 500;
}

.carouselitem {
  height: 400px !important;
  width: 100%;
}

.city {
  height: 400px;
  width: 632px;
  /* position: absolute; */
  /* margin-top: -420px; */
  /* margin-left: 676px; */
}

/* directors */
.directorbg {
  background: linear-gradient(90deg, #ffffff 0%, #ffe9e4 100%);
}

.drheadline {
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 600;
}

.drname {
  font-size: 29px;
  font-family: "Poppins";
}

.drpost {
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 200;
}

.section {
  height: 190px;
  width: 290px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 3px #00000040;
  position: relative;
  margin: auto;
}

.sectiontext {
  margin-top: 65px;
  position: absolute;
  font-weight: 600;
  font-family: "Poppins";
  text-align: left;
  font-size: 15px;
}

.sectiontop {
  height: 80px;
  width: 280px;
  margin-left: 1rem;
  margin-top: -14rem;
  background: linear-gradient(0deg, #d73439, #fb991a 100%);
  position: absolute;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  font-size: 30px;
  font-weight: 600;
  color: white;
  line-height: 1;
}

/* achivement */

.achiveheadline {
  font-size: 45px;
  font-weight: 600;
  font-family: "Poppins";
}

.achivebg {
  background-image: url("../Assets/Assets/Aboutpage/achive.png");
}

.listtext {
  font-size: 21px;
  font-family: "Century Gothic Paneuropean", sans-serif;
  font-weight: 600;
  word-spacing: 2px;
  line-height: 1.5;
  justify-content: center;
}

.listimg {
  height: 230px;
  width: 230px;
}

.listimgtext {
  font-family: "Century Gothic Paneuropean", sans-serif;
  width: 230px;
  font-weight: 800 !important;
  font-size: 15px;
}

/* opportunities */
.oppobg {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.16) 0%,
    #fedfc7 100%
  );
}

.oppoheadline {
  font-family: "Poppins";
  font-weight: 800;
  font-size: 50px;
}

.oppoimg {
  /* height: 50px; */
  width: 135px;
  position: relative;
}

.oppoimgtext {
  position: absolute;
  margin-top: 120px;
  /* margin-left: -45px; */
  font-family: "Poppins";
  font-weight: 600;
}

/* @media (max-width: 480px) {
  .oppoimgtext {
    margin-top: 155px !important;
  }
} */
@media (max-width: 768px) {
  .listtext {
    font-size: 20px;
    text-align: center;
    /* margin-left: 10px !important; */
  }
  .achiveheadline {
    font-size: 35px;
  }

  .listimgtext {
    text-align: center;
  }

  .drheadline {
    font-size: 25px;
  }

  .achiveheadline {
    font-size: 25px;
  }
  .oppoheadline {
    font-size: 28px;
  }

  /* .oppoimgtext {
    margin-top: 185px;
  } */
  .headline {
    font-size: 26px;
  }
  .drname {
    font-size: 25px !important;
  }
  .drpost {
    font-size: 18px;
  }
  .headlinetext {
    font-size: 11px !important;
    text-align: center !important;
  }
  .carouselitem {
    width: 100%;
    height: 250px !important;
  }
}



.caru .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -105px !important;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

@media (max-width: 768px) {
  .caru .carousel-indicators {
    bottom: -70px !important;
  }
}
.carousel-indicators [data-bs-target] {
  background-color: #815f5f !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: white !important;
}