@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');






/* Container for the road and bike */
.road-container {
  position: relative;
  width: 100%;
  height: 100px;
  /* Fixed height for the road */
  overflow: hidden;
  /* Hide anything that goes out of bounds */
}

/* The road image */
.road-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  /* Ensures the road stretches to fit the container */
}

/* The bike image */
.bike {
  position: absolute;
    
  /* Keeps the bike on the road */
  height: 100px;
  /* Set the size of the bike */
  animation: moveBike 15s linear infinite;
  /* Animation to move the bike */
}

/* Keyframes for bike animation */
@keyframes moveBike {
  0% {
    left: -50px;
    /* Starts off-screen on the left */
  }

  100% {
    left: 100%;
    /* Moves to the right end of the screen */
  }
}

