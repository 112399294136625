.support{
    background: linear-gradient(90deg, #FFFFFF 0%, #FFE9E4 100%);
}

.trademark{
    width: 290px;
    height: 140px;
}

.supporttext{
    font-family: 'Century Gothic Paneuropean', sans-serif;
    font-size: 45px;
}