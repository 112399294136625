.photo{
    width: 100%;
    height: 400px !important;
    border-bottom-color: #C50E22 !important;
    border-radius: 42px !important;
    border-bottom-width: 10px !important;
    border-bottom-style: solid;
    background-color: #FFE9E4 !important;
}

.picture{
    height: 270px;
}
.cardtext{
font-weight: 800;
font-size: 20px !important;
font-family: 'Century Gothic Paneuropean', sans-serif;                                            
}

@media (max-width:768px) {
    .photo{
        width: 320px;
    }
    
}

