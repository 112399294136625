.videobg {
    background: #FFEFEB;
}

.videoheadline {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 50px;
}

.videoimg {
    width: 429px;
    height: 300px;
    margin-left: -33px;
    margin-top: -39px;
}

.videotext {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 600;
    color: white;
    margin-top: -30px;
}

.ourvideoheadline {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 50px;
}

@media (max-width:768px) {
    
    .videoheadline{
        font-size: 25px !important;
    }

    .videotext{
        font-size: 25px;
    }
}