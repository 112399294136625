.logo1 {
  height: 90px;
  /* width: 300px !important; */
  /* margin-left: -20px; */
}

.navMenu {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.navMenu:hover {
  color: #c50c1c !important;
}

.logo2 {
  height: 70px;
  width: 160px;
  justify-content: start;
}

.img4 {
  width: 100%;
}

.lghead {
  width: 100%;
}

.bottombg {
  background-image: url(../Assets/Assets/Bottom/bottombg.jpg);
  background-size: cover;
}

@media (max-width: 768px) {
  .bottombg {
    width: 100%;
    height: 100%;
  }

  .rto {
    font-size: 15px !important;
  }

  .license {
    height: 40px;
    width: 90% !important;
    justify-content: center;
    margin: 0px;
    text-align: center;
  }

  .whitebox {
    height: 490px !important;
  }

  .whiteboxheadline {
    font-size: 25px !important;
  }

  .homecounterheadline {
    font-size: 35px !important;
  }

  .navMenu {
    font-size: 25px;
  }

  .navMenu:hover {
    color: #c50c1c !important;
  }

  .eventdrop {
    font-size: 30px !important;
  }

  .events {
    width: 100% !important;
    font-size: 25px !important;
    text-align: center;
  }

  .whiteboxbtn {
    justify-content: center;
  }

  .wappIcons {
    width: 20% !important;
  }

}

.bottomheadline {
  font-family: "Century Gothic Paneuropean", sans-serif;
  font-weight: 700;
  font-size: 33px;
}

.quicklinks {
  color: white;
  font-size: 20px;
}

.quicklinks:hover {
  font-size: 22px;
}

.loc {
  margin-top: 50px;
  margin-left: -200px;
  color: #fff;
}

.footericon {
  height: 40px;
  width: 40px;
  background-color: white;
}

.footericon:hover {
  background-color: #c50c1c;
}

.followicon {
  height: 20px;
  width: 20px;
  color: black;
}

.homecounterheadline {
  /* color: #F96945; */
  font-family: "Century Gothic Paneuropean", sans-serif;
  font-weight: 600;
  font-size: 50px;
}

.pitch {
  background-image: url(../Assets/Assets/Home/pitch.png);
}

.homecounterlist {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 1.5;
  word-spacing: 6px;
}

.rto {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
}

.boxheadline {
  font-family: "Century Gothic Paneuropean", sans-serif;
  font-weight: 700;
}

.circle {
  /* position: absolute; */
  background-image: url(../Assets/Assets/Homecounter/circle1.png);
  height: 250px;
  background-size: cover;
}

.circle2 {
  /* position: absolute; */
  height: 250px;
  background-size: cover;
}

.copyright {
  font-size: 15px;
  color: white;
}

.event-dropdown .dropdown-toggle {
  color: black !important;
  font-size: 20px;
  font-weight: 600;
  background-color: none;
}

.event-dropdown .dropdown-toggle:hover {
  color: #c50c1c !important;
  /* background-color: none; */
}

.events {
  font-size: 20px;
  font-weight: 600;
  background-color: none !important;
}

.events:hover {
  color: #c50c1c !important;
}

.whiteboxbtn {
  margin: 1px;
}

.wappIcons {
  width: 5%;
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .navMenu {
    font-size: 19px !important;
    font-weight: 600 !important;
  }

  .navMenu:hover {
    color: #c50c1c !important;
  }

  .event-dropdown .dropdown-toggle {
    color: black !important;
    font-size: 19px !important;
    font-weight: 600 !important;
  }

  .events {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .navMenu {
    font-size: 23px !important;
  }

  .event-dropdown .dropdown-toggle {
    font-size: 22px !important;
  }
}

.countHeading {
  color: #0a0a0a;
  font-family: Poppins !important;
  font-size: 22px;
  line-height: 24px;
}

.textCard {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

.iconHover1:hover {
  background-color: blue;
  color: #fff;
}

.iconHover2:hover {
  color: #fff;
  /* background: #833ab4; */
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.iconHover3:hover {
  background-color: red;
  color: #fff;
}

.iconHover4:hover {
  background-color: black;
  color: #fff;
}
.iconHover5:hover {
  background-color: #0077B5;
  color: #fff;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
  color: inherit !important;
}

/* Style for dropdown items on hover */
.event-dropdown .dropdown-item:hover {
  color: rgb(181, 46, 46) !important;
}
a:hover {
  color: #f17e3f;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  background: lightgray;
  white-space: nowrap;
  position: relative;
}

/* Wrapper to allow continuous scrolling */
.marquee-wrapper {
  display: flex;
  width: 200%; /* Ensures continuous scrolling effect */
  animation: scrollText 10s linear infinite;
}

/* Marquee text */
.marquee {
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0; /* Prevent text from resizing */
  width: 100%; /* Ensures text spans full width */
  display: inline-block;
}

/* Scrolling effect */
@keyframes scrollText {
  from { transform: translateX(0); } /* Start from right */
  to { transform: translateX(-50%); } /* Move left and seamlessly repeat */
}