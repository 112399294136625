.body {
    background: #F7F7F7;

}

.Fheadline
 {
    font-size: 37px !important;
    font-family: 'Century Gothic Paneuropean', sans-serif;

}


@media (max-width: 900px) {
    .Fheadline
     {
        font-size: 35px !important;
        text-align: start;
        /* margin: 15px !important; */
        line-height: 1.1 !important;
    }

    .fpara1 {
        /* font-size: 8px !important; */
        margin-top: 20px !important;
        text-align: start;
        margin: 0px !important;
    }

    .fpara2 {
        font-size: 18px !important;
        margin: 8px !important;
        text-align: start;
    }
}

.fpara1 {
    font-size: 26px !important;
    font-family: 'Century Gothic Paneuropean', sans-serif;
    line-height: 1.2;
    word-spacing: 2px;
    margin-left: 3rem;

}

.fpara2 {
    font-size: 21px;
    font-family: "Poppins";
    font-weight: 500;
    margin-left: 3rem;
}

.group {
    /* width: 520px; */
    height: 500px !important;
}

@media (min-width:1920px) {
    .Fheadline
     {
        font-size: 50px !important;
    }

    .fpara1 {
        font-size: 30px !important;
    }

    .fpara2 {
        font-size: 25px;
    }

    .group {
        height: 600px !important;
    }
}

@media (min-width: 1280px) {
    .Fheadline
    {
        /* font-size: 30px !important; */
    }
    .group{
        height: 600px !important;
        /* width: 600px; */
    }
    
}