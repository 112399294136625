.calender {
  height: 750px;
  width: 1000px;
  box-shadow: 12px 13px 120px 0px #00000040;
  border-radius: 50px;
  background-color: white;
}

.calenderheadline {
  font-family: "Century Gothic Paneuropean", sans-serif;
  font-size: 40px;
  font-weight: 800 !important;
}

.btn {
  border: 0px;
  background: none;
  outline: none;
}

.arrowimg {
  margin-top: -20px;
  border: 0px;
  outline: none;
}

.datebox {
  height: 110px;
  width: 130px !important;
  border: 2px solid grey;
  margin-left: -20px;
}

.holidaybox {
  background: #ffe2db;
  color: #c50e22;
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins";
}

.availablebox {
  background: #dbffe3;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins";
}

.btn:disabled,
fieldset:disabled .btn {
  border-color: floralwhite !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: floralwhite !important;
}

.calendertabs {
  font-weight: 700;
  font-size: 10px;
  background-color: none !important;
  /* border: 1px gray solid; */
  /* width: 185px; */
}
.calendertabs:hover {
  /* font-size: 15px; */
  font-weight: 600;
  color: red;
  background-color: none !important;
  border-color: none;
  /* background-color: #FFA088; */
}

@media (max-width: 768px) {
  .calenderheadline {
    font-size: 25px;
  }
}

.btn3d.btn-default {
  color: #666666;
  box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.1) inset,
    0 8px 0 0 #bebebe, 0 8px 8px 1px rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
}

.btn3d.btn-default:active,
.btn3d.btn-default.active {
  color: #666666;
  box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
/* .btn3d:hover {
  color: rgb(247 130 59) !important;
} */
.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* color: var(--bs-nav-tabs-link-active-color); */
  background-color: transparent !important;
  border: none !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border: none !important;
}


.tabsbtn {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}



.custom-button {
  min-height: 70px; /* Minimum height for the buttons */
  background-color: white; /* Default background color */
  color: black; /* Default text color */
  border: 2px solid black; /* Border around the button */
  border-radius: 5px; /* Rounded corners */
  transition: color 0.3s, background-color 0.3s; /* Smooth transition */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/* Selected button styles */
.selected {
  background-color: #feeeea; /* Background color for selected */
  color: orange; /* Text color for selected */
}

/* Hover effect */
.custom-button:hover {
  background-color: #f0f0f0; /* Light grey on hover */
}


.holiday-text {
  color: red;
  font-weight: bold;
  font-size: 12px;
}


/* Hide the full date label and show only the first letter on mobile */
@media (max-width: 768px) {
  .date-label {
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 15px;
    background-color: #f0f0f0; /* Example background */
    color: #333; /* Example text color */
  }

  .date-label span {
    display: inline-block;
    font-size: 10px;
  }

  .date-label span::after {
    content: attr(data-first-letter);
  }
}

