.text {
    font-size: 45px;
    font-weight: 700;
}

.vbg {
    width: 100%;
    height: 350px;
    position: relative;
    background-image: url("../Assets/Assets/Vission/road1.png");
    background-size: cover;
    background-position: center;
}

.mbg {
    width: 100%;
    height: 350px;
    position: relative;
    background-image: url("../Assets/Assets/Vission/road2.png");
    background-size: cover;
    background-position: center;
}

.vtext {
    color: white;
    font-family: 'Century Gothic Paneuropean', sans-serif;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    position: absolute;

}


@media (max-width:576px) {
    .vbg {
        background-repeat: no-repeat;
    }

    .vtext {
        font-size: 15px !important;
        text-align: start;
    }

    .mtext {
        font-size: 17px !important;
        text-align: start;
        /* padding: 20px; */
    }

    .missionimg {
        width: 50px !important;
        height: 50px !important;
    }
}



.mtext {
    color: white;
    font-family: 'Century Gothic Paneuropean', sans-serif;
    font-size: 25px;
    line-height: 1.25;
}

.missionimg {
    width: 120px;
    height: 105px;
}

.imgtext {
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
}

.road {
    position: relative;
    width: 100%;
    height: 300px;
    /* Increased height for two lanes */
    background-color: #333;
    /* Dark gray for road */
    overflow: hidden;
 
    /* Space for the road */
}

/* White dashed line divider in the middle */
.divider {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 5px;
    background: repeating-linear-gradient(to right, white 0, white 30px, transparent 30px, transparent 60px);
    transform: translateY(-50%);
    z-index: 2;
}

/* Vehicles section */
.vehicles {
    position: absolute;
    width: 100%;
    z-index: 1;
}

/* Vehicle common styles */
.vehicle {
    position: absolute;
    width: 160px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    /* Vehicles are on the road */
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    /* Keep at the end of the animation */
}

/* Top lane vehicles (left to right) */
.top-lane .vehicle {
    animation-direction: normal;
    /* Move left-to-right */
}

/* Bottom lane vehicles (right to left) */
.bottom-lane .vehicle {
    animation-direction: reverse;
    /* Move right-to-left */
}

/* Vehicle Images */
.truck {
    background-image: url('../Assets/Assets/Home/image 721.png');
    animation: moveVehicle 18s infinite linear;
    top: 20px;
}

.car {
    background-image: url('../Assets/Assets/Home/image 720.png');
    animation: moveVehicle 17s infinite linear;
    top: 80px;
}

.auto {
    background-image: url('../Assets/Assets/Home/image 715.png');
    animation: moveVehicle 14s infinite linear;
    top: 140px;
}

.bicycle {
    background-image: url('../Assets/Assets/Home/image 718.png');
    animation: moveVehicle 16s infinite linear;
    top: 200px;
}

.taxi {
    background-image: url('../Assets/Assets/Home/image 714.png');
    animation: moveVehicle 18s infinite linear;
    top: 280px;
}

/* Animations for all Vehicles */
@keyframes moveVehicle {
    0% {
        transform: translateX(-100vw);
        /* Start from outside the left */
    }

    100% {
        transform: translateX(110vw);
        /* End outside the right */
    }
}