
.html5-video-container{
    z-index: 10;
    position: relative;
}

.video-stream{
    position: absolute;
    display: block;
}

.vdoheadline{
    font-size: 35px;
    font-family: "Poppins";
    font-weight: 600;
}