.investorbg {
    background: #FFEFEB;
    /* height: 200px; */
    position: relative;
}

.inverstorheadline {
    font-size: 50px;
    font-weight: 800;
    font-family: "Poppins";
}
.returntext {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 25px;
    line-height: 0.5;
}



@media (max-width:768px) {
    .inverstorheadline {
        font-size: 20px;
    }

    .investorbg {
        height: 100px;
    }
    .returntext {
        font-size: 17px;
        /* line-height: 1.5; */
    }
}

@media (max-width:1920px) {
    .return {
        margin-top: -100px !important;
    }

}