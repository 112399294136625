.upcomingbg {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.16) 0%, #FEDFC7 100%);
}

.upcomingheadline {
    font-size: 50px;
    font-weight: 800;
    font-family: "Poppins";
}

.event {
    background-color: white;
    height: 260px;
    width: 870px !important;
    /* transform: skew(165deg ); */
    transform: skew(165deg,0deg);
    box-shadow: 4px 4px 4px 0px #00000040;
    border-radius: 10px;
    /* position: relative; */
    margin-top: 80px;
  
}
@media (max-width: 768px) {
    .event{
        height: 280px;
        padding: 0.5rem;
    }
}
.eventimg {
    /* position: absolute; */
    height: 300px;
    width: 350px;
    transform: skewX(194deg);
    /* margin-top: -280px;*/
    margin-left: -35px; 

}
@media (min-width:820px) {
    .eventimg{
        height: 300px;
        width: 300px;
    }
    
}

.upcomingtext1 {
    font-size: 25px;
    font-weight: 600;
    color: #F96945;
    font-family: 'Century Gothic Paneuropean', sans-serif;
    position: relative;
}

.upcomingtext2 {
    font-family: 'Century Gothic Paneuropean', sans-serif;
}

.coll {
    transform: skewX(194deg);
}

.soontext {
    margin-left: 240px;
    color: black;
    font-size: 15px;
}

.soonbox {
    height: 40px !important;
    width: 40px !important;
    background-color: #F96945;
    position: absolute;
    text-align: center;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

@media (max-width:768px) {

    .upcomingheadline {
        font-size: 30px;
    }

    .event {
        width: 290px !important;
        transform: skewX(0deg);
    }

    .upcomingtext1 {
        font-size: 20px;
    }

    .upcomingtext2 {
        font-size: 15px;
    }

    .coll {
        transform: skewX(0deg);

    }

}


/* .animate-seconds {
    animation: fade-in 0.5s ease;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
   */